<ng-template pTemplate="header">
  <i class="pi pi-th-large"></i>
  <span class="p-2">Dashboard</span>
</ng-template>

<div *ngIf="paymentStage===1">
  <div class="flex flex-wrap  card-container align-items-center justify-content-center pt-4 "
    *ngIf="currency ==='INR' ">
    <div class="col-12 right-button">
      <p> To know our modality rates per study.. &nbsp;<a class="link" (click)="modalityPriceList()">Click here </a>
      </p>
    </div>
    <!-- <------------------------------admin------------------------------------------------->
    <div (click)="changeNextpage(300)" class="card homeCard ">
      <h1>300 INR </h1>

    </div>
    <div (click)="changeNextpage(500)" class="card homeCard ">
      <h1>500 INR </h1>

    </div>
    <div (click)="changeNextpage(1000)" class="card homeCard ">
      <h1>1000 INR </h1>

    </div>
    <div (click)="changeNextpage(2000)" class="card homeCard ">
      <h1>2000 INR </h1>

    </div>
    <div (click)="changeNextpage(3000)" class="card homeCard ">
      <h1>3000 INR </h1>

    </div>

    <div class="card homeCard">
      <h1>{{ customAmount }} INR </h1>

      <input pInputText id="username" [(ngModel)]="customAmount" maxlength="5" type="text" />

      <div class="p-3">
        <!-- <button pButton pRipple type="button" label="Submit" (click)="changeNextpage(customAmount)"
          [disabled]="customAmount < 300 || !customAmount || customAmount % 10 !== 0"
          class="p-button-raised p-button-success"></button> -->
        <button pButton pRipple type="button" label="Submit" (click)="changeNextpage(customAmount)"
          class="p-button-raised p-button-success"></button>
      </div>
      <div>
        <b><u>Note:-</u></b>
        <p>Amount must be a minimum of 300 and a multiple of 10.</p>
      </div>

    </div>

  </div>

  <div class="flex flex-wrap card-container align-items-center justify-content-center pt-4 " *ngIf="currency==='SAR' ">
    <!-- <------------------------------admin------------------------------------------------->
    <div (click)="changeNextpage(50);" class="card homeCard">
      <h1>50 SAR </h1>
    </div>
    <div (click)="changeNextpage(100);" class="card homeCard">
      <h1>100 SAR </h1>
    </div>
    <div (click)="changeNextpage(500);" class="card homeCard">
      <h1> 150 SAR </h1>
    </div>
    <div (click)="changeNextpage(1000);" class="card homeCard">
      <h1>200 SAR </h1>
    </div>
    <div (click)="changeNextpage(1500);" class="card homeCard">
      <h1>250 SAR </h1>
    </div>
    <div (click)="changeNextpage(2000);" class="card homeCard">
      <h1> 300 SAR </h1>
    </div>
    <div class="card homeCard">
      <h1>{{ customAmount }} SAR </h1>

      <input pInputText id="username" [(ngModel)]="customAmount" maxlength="5" type="text" />

      <div class="p-3">
        <!-- <button pButton pRipple type="button" label="Submit" (click)="changeNextpage(customAmount)"
          [disabled]="customAmount < 300 || !customAmount || customAmount % 10 !== 0"
          class="p-button-raised p-button-success"></button> -->

        <button pButton pRipple type="button" label="Submit" (click)="changeNextpage(customAmount)"
          [disabled]="!customAmount" class="p-button-raised p-button-success"></button>
      </div>
      <div>
        <b><u>Note:-</u></b>
        <p>Amount must be a minimum of 300 and a multiple of 10.</p>
      </div>

    </div>
  </div>

</div>

<!-- --------------------------------------stage -2 ------------------------------------------------------ -->
<div class="grid" *ngIf="paymentStage===2 ">
  <div class="col-12 md:col-6 lg:col-6">
    <form [formGroup]="billinginfo.form">
      <div class="card">
        <h1>Billing Information </h1>
        <div class="grid fluid">
          <div class="field col-12 md:col-12 lg:col-4">
            <label for="Title">Billing Name: <span class="validation-star">*</span></label>
            <input pInputText id="username" maxlength="50" [readonly]="editable" formControlName="name" type="text"
              pAutoFocus autofocus="true" />
            <f-error [err]="billinginfo.required('name')" msg="Billing Name is required"></f-error>
          </div>
          <div class="field col-12 md:col-6 lg:col-4">

            <label>Phone Number <span class="validation-star">*</span></label>
            <input pInputText maxlength="18" formControlName="phone" type="text" [readonly]="editable">
            <f-error [err]="billinginfo.required('phone')" msg="Phone Number is required"></f-error>
            <f-error [err]="billinginfo.pattern('phone')" msg="Only numerical values are accepted "></f-error>
            <f-error [err]="billinginfo.minlength('phone')" msg="Enter a minimum of 7 digits"></f-error>

          </div>
          <div class="field col-12 md:col-12 lg:col-4">
            <label for="Title">Company:</label>
            <span class="p-float-label">
              <input pInputText id="username" maxlength="50" [readonly]="editable" formControlName="company"
                type="text" />
            </span>
          </div>
          <div class="field col-12 md:col-12 lg:col-4">
            <label for="Title">Tax No:</label>
            <span class="p-float-label">
              <input pInputText id="username" [readonly]="editable" maxlength="50" formControlName="taxNo"
                type="text" />
            </span>
          </div>
          <div class="field col-12 md:col-12 lg:col-4">
            <label for="Title">Country: <span class="validation-star">*</span></label>
            <p-autoComplete formControlName="countryId" *ngIf="!editable" [suggestions]="countryLookup"
              (onSelect)="stateAreaOnChange($event)" forceSelection="true" maxlength="60"
              (completeMethod)="filterCountry($event)" field="name" ariaLabel="Country" [dropdown]="true"
              [autoHighlight]="true" showEmptyMessage="true" delay="400">
            </p-autoComplete>
            <input pInputText id="countryId" maxlength="50" *ngIf="editable" [readonly]="editable"
              value="{{billinginfo?.get('countryId')?.name}}" type="text" pAutoFocu [readonly]="editable" />
            <f-error [err]="billinginfo.required('countryId')" msg="Country is required"></f-error>

          </div>
          <div class="field col-12 md:col-12 lg:col-4">
            <label for="Title">State:</label>
            <p-autoComplete formControlName="stateId" *ngIf="!editable" [suggestions]="stateLookup"
              (completeMethod)="filterState($event)" maxlength="60" forceSelection="true" field="name" ariaLabel="State"
              [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400">
            </p-autoComplete>
            <input pInputText id="stateId" maxlength="50" *ngIf="editable" [readonly]="editable"
              value="{{billinginfo?.get('stateId')?.name}}" type="text" pAutoFocu [readonly]="editable" />
          </div>
          <div class="field col-12 md:col-12 lg:col-4">
            <label for="Title">City:</label>
            <span class="p-float-label">
              <input pInputText id="username" maxlength="50" [readonly]="editable" formControlName="city" type="text"
                pAutoFocu [readonly]="editable" />
            </span>
          </div>
          <div class="field col-12 md:col-12 lg:col-4">
            <label for="Title">Zipcode: <span class="validation-star">*</span></label>
            <span class="p-float-label">
              <input pInputText id="username" maxlength="12" [readonly]="editable" formControlName="zip" type="text"
                pAutoFocus />
            </span>
            <f-error [err]="billinginfo.required('zip')" msg="Zip is required"></f-error>
            <f-error [err]="billinginfo.pattern('zip')" msg=" Only alphanumeric characters are accepted"></f-error>

          </div>
          <div class="field col-12 md:col-6 lg:col-4">
            <label for="email">Email Address:</label>
            <input pInputText id="username" maxlength="50" [readonly]="editable" formControlName="email" type="text" />
            <f-error [err]="billinginfo.required('email')" msg="Email Address is required"></f-error>
            <f-error [err]="billinginfo.pattern('email')"
              msg="This e-mail address is not valid. Valid records include to user@somehost.somedomain. And emails only accept lowercase letters.">
            </f-error>
          </div>
          <div class="field col-12 md:col-12 lg:col-12">
            <label for="Title">Address:</label>
            <textarea class="p-inputtextarea
              p-inputtext p-component p-element" [rows]="3" [cols]="40" maxlength="480" [readonly]="editable"
              formControlName="address" pInputTextarea></textarea>
          </div>
        </div>
        <div class="formgrid text-center">
          <button pButton pRipple type="button" label="Edit Address" (click)="toggleEditable()" *ngIf="editable"
            class="p-button-raised p-button-success"></button>
          <button pButton pRipple type="button" label="Save" (click)="saveBilling(false)" *ngIf="!editable"
            class="p-button-raised p-button-success"></button>
        </div>
      </div>
    </form>
  </div>
  <div class="col-12 md:col-6 lg:col-6 ">
    <form [formGroup]="billinginfor.form">
      <div class="card resol">

        <h1>Proceed To Pay {{billingDetailsData?.amount}} {{currency}} </h1>
        <div class="grid fluid justify-content-center ">
          <div class="col-12 md:col-12 lg:col-12 mt-3">
            <label for="email">Billing Address:</label>
            <textarea class="p-inputtextarea p-inputtext p-component p-element" [value]="'Name: ' + (billinginfo?.get('name') ? billinginfo.get('name') : 'N/A') + '&#13;&#10;' +
                    'Address: ' + (billinginfo?.get('address') ? billinginfo.get('address') : 'N/A') + '&#13;&#10;' +
                    'Zipcode: ' + (billinginfo?.get('zip') ? billinginfo.get('zip') : 'N/A') + '&#13;&#10;' +
                    'Country: ' + (billinginfo?.get('countryId')?.name ? billinginfo.get('countryId')?.name : 'N/A') + '&#13;&#10;' +
                    'State: ' + (billinginfo?.get('stateId')?.name ? billinginfo.get('stateId')?.name : 'N/A') + '&#13;&#10;' +
                    'Email Id: ' + (billinginfo?.get('email') ? billinginfo.get('email') : 'N/A') + '&#13;&#10;' +
                    'Ph: ' + (billinginfo?.get('phone') ? billinginfo.get('phone') : 'N/A')" [rows]="9" [cols]="40"
              [readonly]="true" pInputTextarea>
  </textarea>


          </div>

          <div class=" col-12 md:col-12 lg:col-12 ">
            <p-checkbox name="checkProgress" formControlName="checkProgress" value="1" required></p-checkbox>

            <span class="pl-2"> I have read and agreed to the &nbsp;<a (click)="termsConditionsPdf()"><u>terms and
                  conditions.</u>
              </a> </span>

          </div>

          <div class=" col-12 md:col-12 lg:col-12  ml ">
            <label> Don't refresh your page on payment submit.&nbsp;&nbsp;</label><i
              class="pi pi-exclamation-triangle  ">
            </i>
            <div *ngIf="errFlag && billinginfor.isEmpty('checkProgress')">
              <small class="p-error">Please check the box if you want to proceed.</small>
            </div>

          </div>

        </div>

        <div class=" grid justify-content-center mt-3 bc ">
          <button pButton pRipple type="button" [disabled]="sysService.loading" label="Cancel Payment"
            icon="pi pi-times" class="p-button-outlined p-button-info m-2" (click)="payToBack()"></button>
          <button pButton pRipple type="button" [label]="payWithUPI ? 'Pay With Card' : 'proceed to pay'"
            icon="pi pi-credit-card" [disabled]="sysService.loading" class="p-button-outlined p-button-warning m-2"
            (click)="payToProgress()">
            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
          </button>

          <button pButton pRipple type="button" *ngIf="payWithUPI" label="Pay With UPI" icon="pi pi-paypal"
            class="p-button-outlined p-button-warning m-2" [disabled]="sysService.loading"
            (click)="payWithCard(customAmount)"> <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
          </button>



        </div>
      </div>
    </form>

  </div>

</div>

<p-dialog header="Modality Rates" [(visible)]="modalityPriceTable" [style]="{width: '50vw'}"
  [breakpoints]="{ '991px': '75vw', '575px': '95vw' }">
  <p-table #dt [value]="modalityRates.list" [scrollable]="true" scrollHeight="65vh" [totalRecords]="modalityRates.total"
    [lazy]="true" (onLazyLoad)="listmodalityRatesLazy($event)" dataKey="id" [(selection)]="modalityRates.selection"
    [rowsPerPageOptions]="[25, 50]" [loading]="sysService.loading" filterDelay="400"
    currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowHover]="true" styleClass="p-datatable-sm"
    [rows]="25" [paginator]="true" [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
    styleClass="p-datatable-sm p-datatable-gridlines" [globalFilterFields]="['name', 'rate', 'description']">
    <ng-template pTemplate="paginatorleft">

    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Display Name
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="code">
          Modality Code
          <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th pSortableColumn="rate">
          Rates<p-sortIcon field="rate"></p-sortIcon>
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rates>
      <tr>
        <td>{{ rates.name }}</td>

        <td>{{ rates.code }}</td>

        <td>{{ rates.rate }}</td>

      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td class="norecord" [attr.colspan]="7">No records found</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>